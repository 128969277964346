/* eslint-disable sonarjs/cognitive-complexity */
import moment from 'moment';
import {
  uniq,
  uniqBy,
  isArray,
  isNil
} from 'lodash';
import { CatalogWithExternalEntitiesDto } from '../../apis/sherpath-course-management-service/sherpath-course-management-service.dtos';
import { coursewareActionType } from './courseware.actions';
import { PrimaryTaxonomy } from '../../apis/rec-gateway/rec-gateway.models';
import { SyllabusItemDto } from '../../apis/sherpath-syllabus-service/sherpath-syllabus-service.dtos';
import {
  coursewareInitialState,
  initialFilterState
} from './courseware.constants';
import {
  CoursewareStore,
  CourseBuilderStore,
  CourseBuilderField,
  ReduxPageWithCatalog,
  ReduxPageWithPrimaryTaxonomies,
} from './courseware.models';
import {
  contentCreateMethod,
  courseCreateMethod
} from '../../pages/course-builder/courseBuilder.constants';
import {
  AssessmentDto,
  AssignmentDto
} from '../../apis/eols-assessment-service/eols-assessment-service.dtos';

const getNextSyllabusItems = (
  state: CoursewareStore,
  payload: SyllabusItemDto[] | SyllabusItemDto
): SyllabusItemDto[] => {
  if (isNil(payload)) {
    return state.syllabusItems;
  }
  const newSyllabusItems = isArray(payload) ? payload : [payload];
  return uniqBy([...newSyllabusItems, ...state.syllabusItems], 'id');
};

// TODO: Merge this function with below getNextAssignments and make it more generic for array type property in redux
const getNextAssessments = (
  state: CoursewareStore,
  payload: AssessmentDto[] | AssessmentDto
): AssessmentDto[] => {
  if (isNil(payload)) {
    return state.assessments;
  }
  const newAssessments = isArray(payload) ? payload : [payload];
  return uniqBy([...newAssessments, ...state.assessments], 'id');
};

const getNextAssignments = (
  state: CoursewareStore,
  payload: AssignmentDto[] | AssignmentDto
): AssignmentDto[] => {
  if (isNil(payload)) {
    return state.assignments;
  }
  const newAssignments = isArray(payload) ? payload : [payload];
  return uniqBy([...newAssignments, ...state.assignments], 'id');
};

const getEndDate = (state: CourseBuilderStore): Date => {
  if (state[CourseBuilderField.COURSE_CREATE_METHOD] === courseCreateMethod.NEW
    && state[CourseBuilderField.CONTENT_CREATE_METHOD] === contentCreateMethod.TEMPLATE
    && state[CourseBuilderField.NUMBER_OF_SECTIONS] > 0
    && state[CourseBuilderField.START_DATE]) {
    const endDate = moment(state[CourseBuilderField.START_DATE])
      .add(state[CourseBuilderField.NUMBER_OF_SECTIONS], 'weeks')
      .add(-1, 'days')
      .toDate();

    if (!moment(endDate)
      .isSame(moment(state[CourseBuilderField.END_DATE]))) {
      return endDate;
    }
  }
  return state[CourseBuilderField.END_DATE];
};

const getNewCourseBuilderState = (state, payload: Partial<CourseBuilderStore>): CourseBuilderStore => {
  const newState: CourseBuilderStore = {
    ...state.courseBuilderState,
    ...payload
  };
  newState[CourseBuilderField.END_DATE] = getEndDate(newState);
  return newState;
};

export const coursewareReducer = (state = coursewareInitialState, action): CoursewareStore => {
  // eslint-disable-next-line sonarjs/max-switch-cases
  switch (action.type) {
    case coursewareActionType.REQUEST_START:
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount + 1
      };
    case coursewareActionType.REQUEST_SUCCESS:
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1
      };
    case coursewareActionType.REQUEST_ERROR:
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
        errors: [action.payload, ...state.errors]
      };
    case coursewareActionType.RESTORE_STATE:
      return {
        ...coursewareInitialState,
        ...action.payload
      };
    case coursewareActionType.SET_ISBNS:
      return {
        ...state,
        isbns: action.payload
      };
    case coursewareActionType.SET_USER:
      return {
        ...state,
        courseSectionId: action.payload.courseSectionId.toString(),
        roleId: action.payload.roleId,
        userId: action.payload.userId
      };
    case coursewareActionType.SET_REGISTERED_TOKEN:
      return {
        ...state,
        registeredToken: action.payload
      };
    case coursewareActionType.FETCH_COURSE_SUCCESS:
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
        currentCourse: action.payload
      };
    case coursewareActionType.POST_COURSE_SUCCESS:
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
        currentCourse: state.currentCourse ? {
          ...state.currentCourse,
          courseName: action.payload.courseName,
          locked: action.payload.locked
        } : action.payload
      };
    case coursewareActionType.FETCH_USERS_SUCCESS:
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
        users: action.payload
      };
    case coursewareActionType.FETCH_EAQ_USERS_SUCCESS:
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
        eaqUsers: action.payload
      };

    case coursewareActionType.FETCH_EAQ_ISBNS_SUCCESS:
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
        eaqIsbns: action.payload
      };

    case coursewareActionType.FETCH_MASTERY_RESET_SUCCESS:
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
        masteryResetSuccess: action.payload

      };
    case coursewareActionType.FETCH_USER_HISTORY_SUCCESS:
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
        userHistory: uniqBy([...action.payload.response, ...state.userHistory], 'id'),
        userHistoryStateCompletedRequests: uniq([...state.userHistoryStateCompletedRequests, action.payload.stateKey])
      };
    case coursewareActionType.DELETE_USER_HISTORY_SUCCESS:
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
        userHistory: coursewareInitialState.userHistory,
        userHistoryStateCompletedRequests: coursewareInitialState.userHistoryStateCompletedRequests,
        isCourseOwner: coursewareInitialState.isCourseOwner,
        skillSubmissionRecords: coursewareInitialState.skillSubmissionRecords
      };
    case coursewareActionType.FETCH_GROUP_ACTIVITY_SUCCESS:
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
        groupActivity: action.payload
      };
    case coursewareActionType.POST_USER_HISTORY_SUCCESS:
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
        userHistory: uniqBy([action.payload, ...state.userHistory], 'id')
      };
    case coursewareActionType.SET_IS_COURSE_OWNER_SUCCESS:
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
        isCourseOwner: action.payload.stateInfo,
        userCourseOwnerRecords: uniqBy([action.payload, ...state.userCourseOwnerRecords], 'id')
      };
    case coursewareActionType.FETCH_USER_COURSE_OWNER_RECORDS_SUCCESS:
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
        userCourseOwnerRecords: action.payload
      };
    case coursewareActionType.FETCH_LINK_DATA_SUCCESS:
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
        linkData: action.payload
      };
    case coursewareActionType.SET_MESSAGES:
      return {
        ...state,
        messages: action.payload
      };
    case coursewareActionType.FETCH_GROUPED_FEATURE_FLAGS_SUCCESS:
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
        featureFlagsGrouped: action.payload
      };
    case coursewareActionType.FETCH_CATALOG_SUCCESS: {
      const {
        data,
        reduxPage
      } = action.payload as { data: CatalogWithExternalEntitiesDto; reduxPage: ReduxPageWithCatalog };
      let updatedState: Partial<CoursewareStore> = {};
      if (reduxPage) {
        updatedState = {
          [reduxPage]: {
            ...state[reduxPage],
            catalog: data
          }
        };
      } else {
        updatedState.catalog = data;
      }
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
        ...updatedState
      };
    }
    case coursewareActionType.FETCH_PRIMARY_TAXONOMY_SUCCESS: {
      const {
        data,
        reduxPage
      } = action.payload as { data: PrimaryTaxonomy[]; reduxPage: ReduxPageWithPrimaryTaxonomies };
      const primaryTaxonomies = uniqBy([...state.primaryTaxonomies, ...data], (item) => {
        return item.isbn;
      });
      let updatedState: Partial<CoursewareStore> = {};
      if (reduxPage) {
        updatedState = {
          [reduxPage]: {
            ...state[reduxPage],
            primaryTaxonomies
          }
        };
      } else {
        updatedState = {
          primaryTaxonomies
        };
      }
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
        ...updatedState
      };
    }
    case coursewareActionType.SET_CHECKED_SYLLABUS_ITEMS:
      return {
        ...state,
        checkedSyllabusItemIds: action.payload
      };
    case coursewareActionType.SET_IS_BATCH_EDIT_MODE:
      return {
        ...state,
        isBatchEditModeEnabled: action.payload
      };
    case coursewareActionType.SET_IS_DRAG_DROP_MODE:
      return {
        ...state,
        isDragDropModeEnabled: action.payload
      };
    case coursewareActionType.POST_SYLLABUS_ITEMS_SUCCESS:
    case coursewareActionType.PUT_SYLLABUS_ITEMS_BATCH_SUCCESS:
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
        syllabusItems: getNextSyllabusItems(state, action.payload)
      };
    case coursewareActionType.FETCH_SYLLABUS_ITEMS_SUCCESS: {
      const {
        syllabusItems,
        externalEntities
      } = action.payload;
      return {
        ...state,
        externalEntities,
        pendingRequestCount: state.pendingRequestCount - 1,
        syllabusItems
      };
    }
    case coursewareActionType.FETCH_ASSIGNMENTS_SUCCESS: {
      const { assignments } = action.payload;
      return {
        ...state,
        assignments,
        pendingRequestCount: state.pendingRequestCount - 1
      };
    }
    case coursewareActionType.UPDATE_COURSE_BUILDER_STATE:
      return {
        ...state,
        courseBuilderState: getNewCourseBuilderState(state, action.payload)
      };
    case coursewareActionType.POST_SYLLABUS_ASSIGNMENT_SUCCESS:
    case coursewareActionType.PUT_SYLLABUS_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
        assignments: getNextAssignments(state, action.payload.assignment),
        syllabusItems: getNextSyllabusItems(state, action.payload.syllabusItemDTO)
      };
    case coursewareActionType.PUT_ASSIGNMENTS_SUCCESS:
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
        assignments: state.assignments.map((assignment) => {
          if (action.payload[assignment.id]) {
            return action.payload[assignment.id];
          }
          return assignment;
        })
      };
    case coursewareActionType.FETCH_ASSESSMENT_SUBMISSIONS_SUCCESS:
      return {
        ...state,
        pendingRequestCount: action.payload.isTrackRequestCount ? state.pendingRequestCount - 1 : state.pendingRequestCount,
        assessmentSubmissionsMap: {
          ...state.assessmentSubmissionsMap,
          [action.payload.assignmentId]: action.payload.assessmentSubmissions
        }
      };
    case coursewareActionType.FETCH_ALL_ASSESSMENT_SUBMISSIONS_SUCCESS:
      return {
        ...state,
        pendingRequestCount: action.payload.isTrackRequestCount ? state.pendingRequestCount - 1 : state.pendingRequestCount,
        assessmentSubmissionsMap: {
          ...state.assessmentSubmissionsMap,
          ...action.payload.assessmentSubmissionsMap
        }
      };
    case coursewareActionType.FETCH_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        assignment: action.payload,
        pendingRequestCount: state.pendingRequestCount - 1
      };
    case coursewareActionType.FETCH_EOLS_USER_SUCCESS:
      return {
        ...state,
        eolsUser: action.payload,
        pendingRequestCount: state.pendingRequestCount - 1
      };
    case coursewareActionType.FETCH_EVOLVE_USER_SUCCESS:
      return {
        ...state,
        evolveUser: action.payload,
        pendingRequestCount: state.pendingRequestCount - 1
      };
    case coursewareActionType.FETCH_CROSSWALK_USERS_SUCCESS:
      return {
        ...state,
        crosswalkUsers: action.payload,
        pendingRequestCount: state.pendingRequestCount - 1
      };
    case coursewareActionType.SET_ASSESSMENT_START_TIME_MAP:
      return {
        ...state,
        assessmentStartTimeMap: {
          ...state.assessmentStartTimeMap,
          [action.payload.assessmentId]: action.payload.startTime
        }
      };
    case coursewareActionType.SET_APP_LINK_COOKIES:
      return {
        ...state,
        appLinkCookies: {
          ...action.payload
        }
      };
    case coursewareActionType.UPDATE_COLLAPSED_FOLDER_IDS:
      return {
        ...state,
        collapsedFolderIds: action.payload
      };
    case coursewareActionType.FETCH_SKILL_STATIC_DATA:
      return {
        ...state,
        skillStaticData: action.payload,
        pendingRequestCount: state.pendingRequestCount - 1
      };
    case coursewareActionType.FETCH_SKILL_SUBMISSION_RECORD:
      return {
        ...state,
        skillSubmissionRecords: action.payload,
        pendingRequestCount: state.pendingRequestCount - 1
      };
    case coursewareActionType.RESET_STORE:
      return {
        ...coursewareInitialState,
        messages: state.messages
      };
    case coursewareActionType.FETCH_COURSE_COPY_PREVIEW_SUCCESS:
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
        courseBuilderState: {
          ...state.courseBuilderState,
          ...action.payload
        }
      };
    case coursewareActionType.SET_BATCH_EDIT_SELECTED_SYLLABUS_ITEMS:
      return {
        ...state,
        batchEditSelectedSyllabusItems: action.payload
      };
    case coursewareActionType.SET_BATCH_EDIT_UPDATED_SYLLABUS_ITEMS:
      return {
        ...state,
        batchEditUpdatedSyllabusItems: action.payload
      };
    case coursewareActionType.FETCH_USER_ENGAGEMENT_REPORT_SUCCESS:
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
        userEngagementReport: action.payload
      };
    case coursewareActionType.CLEAR_ALL_FILTERS: {
      const updatedState: Partial<CoursewareStore> = {
        [action.payload]: {
          ...state[action.payload],
          filterState: initialFilterState
        }
      };
      return {
        ...state,
        ...updatedState
      };
    }
    case coursewareActionType.FETCH_MODULE_SEQUENCE_SUCCESS:
      return {
        ...state,
        moduleSequenceMap: action.payload,
        pendingRequestCount: state.pendingRequestCount - 1
      };
    case coursewareActionType.FETCH_OSMOSIS_TOKEN_SUCCESS:
      return {
        ...state,
        osmosisTokenDto: action.payload,
        pendingRequestCount: state.pendingRequestCount - 1
      };
    case coursewareActionType.SET_STORE_PROPS:
      return {
        ...state,
        ...action.payload
      };
    case coursewareActionType.FETCH_USER_RECENT_ASSESSMENT_BY_ASSIGNMENT_ID:
    case coursewareActionType.POST_CREATE_ASSESSMENT_FROM_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        assessments: getNextAssessments(state, action.payload),
        pendingRequestCount: state.pendingRequestCount - 1
      };
    case coursewareActionType.SET_ENABLE_DEEP_LINK:
      return {
        ...state,
        enableDeepLink: action.payload
      };
    case coursewareActionType.SET_HAS_RUN_AUTHESS_HEALTH_CHECK:
      return {
        ...state,
        hasRunAuthessHealthCheck: action.payload
      };
    case coursewareActionType.FETCH_MIGRATED_ENTITLEMENTS_SUCCESS:
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
        migratedEntitlements: action.payload
      };
    case coursewareActionType.SET_SYLLABUS_FOLDER_INFO:
      return {
        ...state,
        syllabusFolderInfo: action.payload
      };
    case coursewareActionType.FETCH_AB_TEST_FLAVOR_SUCCESS:
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
        abTestFlavors: [
          ...state.abTestFlavors,
          action.payload
        ]
      };
    case coursewareActionType.RESET_STATE_ON_LAUNCH:
      return {
        ...coursewareInitialState,
        // Window.sessionStorage follows the course switcher new tab so need to clear data
        // These state props are set in app component or app link component
        // before the reset so want to preserve them
        appLinkCookies: state.appLinkCookies,
        registeredToken: state.registeredToken,
        isbns: state.isbns,
        courseSectionId: state.courseSectionId,
        roleId: state.roleId,
        userId: state.userId,
        messages: state.messages
      };
    default:
      return state;
  }
};
